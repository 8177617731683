import * as React from "react"

import MenuPage from "../components/menu-page"
import Subscribe from "../components/subscribe"
import Footer from "../components/footer"
import SEO from "../components/seo"

const PdfsPage = () => (
  <div>
      <SEO 
        title="PDF Catalogs" 
        description="Find out more about our services and products with the exhaustive PDF brochures and online catalogues. <br/><br/>
        You'll be able to check out our website hosting portfolio in detail and check out all the available 
        offers whenever you want."
    />
    <div className="sub-section-wrap">
          <MenuPage/>
    </div>

    <div className="py-12 mt-5">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            <div className="text-center mx-auto">
                <p className="section-title">PDF Catalogs<span role="img" aria-label="emoji">✨ </span></p>
                <p className="section-after-title">
                Find out more about our services and products with the exhaustive PDF brochures and online catalogues. <br/><br/>
                You'll be able to check out our website hosting portfolio in detail and check out all the available 
                offers whenever you want. <br/><br/>At the same time, you could compare our services with other providers 
                out there and see for yourself whether we have the best offer for you.</p>
            </div>
        </div>
    </div>

    <div className="py-32 server-bg">
      <div className="mx-auto max-w-7xl sm:px-6 lg:px-8">
          <div className="overflow-hidden bg-white shadow-xl dark:bg-gray-800 sm:rounded-lg">
              <div>
                  <div className="grid grid-cols-1 bg-gray-200 bg-opacity-25 dark:bg-gray-800 md:grid-cols-2">
                        <div className="p-6 col-span-2 border-t border-gray-200 dark:border-gray-700 md:border-t-1">
                            <div className="grid grid-cols-3 text-center gap-4">
                                <div className="ml-4 text-lg leading-7 text-gray-600 dark:text-gray-200">
                                    <p className="font-semibold p-0 m-0 text-indigo-800">Website Hosting</p>
                                    <div className="mt-2 text-gray-500 dark:text-indigo-400"> 
                                        <a href="https://sheephostingbay.duoservers.com/hosting-brochure/" target="_blank" rel="noreferrer">Hosting Brochure</a><br/>
                                        <a href="https://sheephostingbay.duoservers.com/hosting-catalog/" target="_blank" rel="noreferrer">Hosting Catalog</a><br/>
                                        <a href="https://sheephostingbay.duoservers.com/hosting-portfolio/" target="_blank" rel="noreferrer">Hosting Portfolio</a><br/>
                                    </div>
                                </div>
                                <div className="ml-4 text-lg leading-7 text-gray-600 dark:text-gray-200">
                                    <p className="font-semibold p-0 m-0 text-indigo-800">VPS</p>
                                    <div className="mt-2 text-gray-500 dark:text-gray-400"> 
                                        <a href="https://sheephostingbay.duoservers.com/vps-brochure/" target="_blank" rel="noreferrer">VPS Hosting Brochure</a><br/>
                                        <a href="https://sheephostingbay.duoservers.com/openvz-catalog/" target="_blank" rel="noreferrer">OpenVZ VPS Hosting Catalog</a><br/>
                                        <a href="https://sheephostingbay.duoservers.com/kvm-catalog/" target="_blank" rel="noreferrer">KVM Hosting Catalog</a><br/>
                                    </div>
                                </div>

                                <div className="ml-4 text-lg leading-7 text-gray-600 dark:text-gray-200">
                                    <p className="font-semibold p-0 m-0 text-indigo-800">Website Hosting</p>
                                    <div className="mt-2 text-gray-500 dark:text-indigo-400"> 
                                        <a href="https://sheephostingbay.duoservers.com/semi-dedicated-brochure/" target="_blank" rel="noreferrer">Semi-dedicated Hosting Brochure</a><br/>
                                        <a href="https://sheephostingbay.duoservers.com/semi-dedicated-catalog/" target="_blank" rel="noreferrer">Semi-dedicated Hosting Catalog</a><br/>
                                    </div>
                                </div>
                            </div>
                        
                        </div>

                        <div className="p-6 col-span-2 border-t border-gray-200 dark:border-gray-700 md:border-t-1">
                            <div className="grid grid-cols-3 text-center gap-4">
                                <div className="ml-4 text-lg leading-7 text-gray-600 dark:text-gray-200">
                                    <p className="font-semibold p-0 m-0 text-indigo-800">Dedicated Servers</p>
                                    <div className="mt-2 text-gray-500 dark:text-gray-400"> 
                                        <a href="https://sheephostingbay.duoservers.com/dedicated-brochure/" target="_blank" rel="noreferrer">Dedicated Hosting Brochure</a><br/>
                                        <a href="https://sheephostingbay.duoservers.com/dedicated-catalog/" target="_blank" rel="noreferrer">Dedicated Hosting Catalog</a><br/>
                                    </div>
                                </div>

                                <div className="ml-4 text-lg leading-7 text-gray-600 dark:text-gray-200">
                                    <p className="font-semibold p-0 m-0 text-indigo-800">Domain Names</p>
                                    <div className="mt-2 text-gray-500 dark:text-indigo-400"> 
                                        <a href="https://sheephostingbay.duoservers.com/domains-brochure/" target="_blank" rel="noreferrer">Domains Brochure</a><br/>
                                    </div>
                                </div>
                            
                                <div className="ml-4 text-lg leading-7 text-gray-600 dark:text-gray-200">
                                    <p className="font-semibold p-0 m-0 text-indigo-800">Store Promotions</p>
                                    <div className="mt-2 text-gray-500 dark:text-indigo-400"> 
                                        <a href="https://sheephostingbay.duoservers.com/promo-offers/" target="_blank" rel="noreferrer">Store Promotions Brochure</a><br/>
                                    </div>
                                </div>
                            </div>
                        
                        </div>
                          
                      
                  </div>
              </div>
          </div>
      </div>
    </div>

    <Subscribe/>
    <Footer/>
  </div>
)

export default PdfsPage
